import React from 'react';
import type { GatsbyBrowser } from 'gatsby';

import { ThemeProvider } from '@emotion/react';
import { theme } from './src/theme';

import { UserTypeProvider } from './src/context/userContext';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <UserTypeProvider type="entrepreneur">{element}</UserTypeProvider>
    </ThemeProvider>
  );
};
