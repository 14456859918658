import { Theme } from '@emotion/react';
import { FontWeight } from './types';

export const theme: Theme = {
  typography: {
    h1: {
      fontFamily: 'Epilogue',
      fontSize: 50,
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 500,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'Epilogue',
      fontSize: 30,
      fontWeight: 500,
    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 500,
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontSize: 22,
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 400,
    },
  },

  colors: {
    blue: '#3F8AE2',
    darkBlue: '#171673',
    purple: '#220073',
    white: '#fff',
    green: '#6DCB7A',
    black: '#1E1E1E',
    midGray: '#D1D3D4',
    darkGray: '#333333',
    lightGray: '#F6F6F6',
    lightBlack: '#404040',
  },

  background: {
    lightBlue: '#3f8ae21a',
    lightGreen: '#6dcb7a1a',
    dark: '#220073',
    gray: '#F6F6F6',
    darkGray: '#333333',
    mediumGray: '#E4E4E4',
    darkBlue: '#171673',
    transparent: '#00000000',
  },

  breakpoints: {
    // Screen size
    xs: 0, // extra-small
    sm: 425, // small (mobile)
    md: 768, // medium (tablet)
    lg: 1024, // large
    xl: 1440, // extra-large
  },
};

declare module '@emotion/react' {
  interface Theme {
    colors: {
      blue: string;
      darkBlue: string;
      purple: string;
      white: string;
      green: string;
      black: string;
      midGray: string;
      darkGray: string;
      lightGray: string;
      lightBlack: string;
    };

    background: {
      lightBlue: string;
      lightGreen: string;
      dark: string;
      gray: string;
      darkGray: string;
      mediumGray: string;
      darkBlue: string;
      transparent: string;
    };

    breakpoints: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };

    typography?: {
      h1?: TypographyStyles;
      h2?: TypographyStyles;
      h3?: TypographyStyles;
      h4?: TypographyStyles;
      h5?: TypographyStyles;
      h6?: TypographyStyles;
      subtitle1?: TypographyStyles;
      subtitle2?: TypographyStyles;
      body1?: TypographyStyles;
      body2?: TypographyStyles;
      button?: TypographyStyles;
    };
  }
}

type TypographyStyles = {
  fontFamily: string;
  fontSize: number; // px
  fontWeight: FontWeight;
};
