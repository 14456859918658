import React, { ReactNode, createContext, useState } from "react";
import { UserType, FAQCategory } from "../types";

export const UserContext = createContext<{
  type?: UserType;
  faqCategory?: FAQCategory;
  setType: React.Dispatch<React.SetStateAction<UserType | undefined>>;
  setFaqCategory: React.Dispatch<React.SetStateAction<FAQCategory | undefined>>;
}>({
  type: undefined,
  faqCategory: undefined,
  setType: () => {},
  setFaqCategory: () => {},
});

export const UserTypeProvider = ({ children, type }: { children: ReactNode, type?: UserType }) => {
  const [userType, setUserType] = useState<UserType | undefined>(type);
  const [faqCategory, setFaqCategory] = useState<FAQCategory | undefined>(
    undefined
  );

  return (
    <UserContext.Provider
      value={{
        type: userType,
        setType: setUserType,
        faqCategory,
        setFaqCategory,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
